.f-blue {
    color: #14b280;
}
.l-header {
    margin-bottom: 0;
    box-shadow: none;
}
.l-content .l-center {
    width: 100%;
    background: linear-gradient(to right, #fff 50%, #f0f0f0 50%);
}
.l-content-middle {
    width: 100%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
}
.p-sides {
    padding-bottom: 0;
}
.p-sides-left {
    margin: 0;
    width: 50%;
}
.p-sides-right {
    margin: 0;
    width: 50%;
    border-left: 1px solid #e9e9e9;
}
.p-sides-left-c {
    width: 480px;
    float: right;
}
.p-sides-right-c {
    width: 480px;
    padding-left: 35px;
    float: left;
}
.p-products-list>.grid {
    padding-right: 0;
}
.b-box {
    background: transparent;
    border-radius: 0;
    border: 0;
}
.p-sides-left .b-box  {
    padding-top: 20px;
}
.p-products-list .product-box {
    text-align: left;
}
.p-products-list .product-box>img {
    width: 170px;
    float: left;
    margin-bottom: 20px;
}
.p-second-desc {
    padding-top: 10px;
    word-wrap: break-word;
}
.p-second-desc-head {
    font-size: 24px;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 10px;
}
.p-second-desc>span.p {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 5px;
}
.p-second-desc>span.p:before {
    display: inline-block;
    background-color: #14b280;
    width: 15px;
    height: 2px;
    content: '';
    margin-right: 10px;
    margin-left: -24px;
    box-sizing: border-box;
    vertical-align: middle;
}
.p-checkbox-list {
    margin-left: 0;
    padding-right: 0;
}
.p-checkbox-list>.grid>.g-row {
    border-top: 1px solid #e9e9e9;
    padding-top: 15px;
    padding-bottom: 10px;
}
.p-products-elems-pricebase {
    display: block;
    font-size: 18px;
    margin-top: 20px;
}
.p-products-elems-pricebase.additional {
    margin-top: 10px;
}
.p-products-elems-price {
    display: block;
    font-size: 44px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: normal;
}
.p-products-elems-price.additional {
    margin-top: 10px;
    color: #A4A4A4;
}
.jq-checkbox {
    background: #fff;
    width: 22px;
    height: 22px;
    border: 1px solid #bcbcbc;
    cursor: pointer;
}
.jq-checkbox.checked:before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    margin: 4px;
    background: #14b280;
}
.jq-checkbox.disabled {
    cursor: default;
}
.jq-checkbox.checked.disabled:before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    margin: 4px;
    background: #bcbcbc;
}
.p-checkbox-elem-desc {
    padding-top: 3px;
    padding-right: 10px;
    color: #5a5a5a;
    font-size: 14px;
    word-wrap: break-word;
}
.p-checkbox-elem-desc>h3 {
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}
.p-add-box {
    width: 70px;
}

.p-products-list .p-text {
    padding-top: 30px;
    border-top: 1px solid #e9e9e9;
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    padding-bottom: 60px;
}

.p-sides-right .b-box-header {
    height: 90px;
    padding: 34px 0;
    background-color: #dfdfdf;
    font-size: 24px;
    border-radius: 0;
}
.lang-de .p-sides-right .b-box-header {
    padding: 36px 0;
    font-size: 16px;
    background-position: 190px center;
}
.lang-fr .p-sides-right .b-box-header {
    padding: 36px 0;
    font-size: 15px;
    background-position: 190px center;
}
.lang-es .p-sides-right .b-box-header {
    padding: 36px 0;
    font-size: 14px;
    background-position: 190px center;
}
.lang-br .p-sides-right .b-box-header {
    padding: 36px 0;
    font-size: 16px;
    background-position: 190px center;
}
.lang-it .p-sides-right .b-box-header {
    padding: 36px 0;
    font-size: 15px;
    background-position: 190px center;
}
.ssl-cont {
    background-color: #fff;
}
.p-trust {
    min-height: 50px;
    /*border-top: 1px solid #e9e9e9;*/
}
.p-trust .trust {
    width: 120px;
    height: 121px;
    padding-top: 25px;
    /*background: url('trust.png') 0 0 no-repeat;*/
}
.satisfaction {
    color: #b79030;
    font-size: 18px;
    line-height: 20px;
    padding-top: 5px;
    font-weight: 400;
    min-height: 45px;
}
.lang-de .satisfaction {
    font-size: 17px;
    padding-top: 15px;
}
.satisfaction>span {
    font-size: 48px;
    float: left;
    line-height: 48px;
    margin-top: -3px;
    margin-right: 10px;
    font-weight: 200;
}
.lang-de .satisfaction>span {
    margin-top: -13px;
}
.lang-es .satisfaction>span {
    float: right;
}
.p-trust .days {
    font-size: 14px;
    color: #000;
    padding-top: 20px;
}
.p-secure {
    background: url('../img/lock.png') 0 center no-repeat transparent;
    height: 90px;
    font-size: 14px;
    padding: 27px 0;
    padding-left: 65px;
    text-align: left;
    margin-bottom: 0;
}
.paddle-container {
    min-height: 450px;
}
.paddle-frame {
    background-color: transparent;
}

.p-total {
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
    padding-bottom: 20px;
}
.p-total .p-t11{
    text-align: right;
    font-size: 24px;
    color: #000;
    padding-top: 20px;
}
.p-total .p-t12{
    text-align: left;
    font-size: 44px;
    color: #000;
}

.p-total .p-t21{
    text-align: right;
    font-size: 18px;
    color: #a1a1a1;
    padding-top: 20px;
}
.p-total .p-t22{
    text-align: left;
    font-size: 30px;
    color: #a1a1a1;
    padding-top: 10px;
}
.l-footer-padder {
    height: 90px;
}

/*РџРѕРїР°РїС‹ РґРѕРї. СЃРѕС„С‚Р°*/
.addsoft-popup-aus {
    display: none;
    background: rgba(0,0,0,.8);
    position: fixed;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 8;
}
.addsoft-popup-body {
    position: fixed;
    top: 2%;
    max-height: 96%;
    left: 50%;
    width: 583px;
    text-align: left;
    background-color: #fff;
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
}
.addsoft-popup-body.pcb>h1 {
    height: 227px;
    background-size: 100% 100%;
    font-weight: normal;
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    padding: 52px 280px 0 50px;
    line-height: auto;
}
.addsoft-popup-body.pcb>h1 {
    /*background: url('pcb-h.png') 0 0 no-repeat;*/
}
.addsoft-popup-body.pcb>h1>b {
    font-size: 48px;
    line-height: 60px;
    font-weight: 500;
}
.addsoft-popup-body.fr>h1 {
    height: 282px;
    background-size: 100% 100%;
    font-weight: normal;
    color: #000;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 25px;
    line-height: auto;
}
.addsoft-popup-body.fr>h1 {
    /*background: url('fr-h.png') 0 0 no-repeat;*/
}
.addsoft-popup-body>.p {
    padding-left: 60px;
    margin: 0 30px;
    min-height: 49px;
    background-repeat: no-repeat;
    background-position: left center;
    color: #000;
    font-size: 14px;
    line-height: 24px;
}
.addsoft-popup-body>.p>h2 {
    font-size: 17px;
    margin: 0;
}
.addsoft-popup-body>.p.pcb-n1 {
    /*background-image: url('pcb-n1.png');*/
}
.addsoft-popup-body>.p.pcb-n2 {
    /*background-image: url('pcb-n2.png');*/
}
.addsoft-popup-body>.p.pcb-n3 {
    /*background-image: url('pcb-n3.png');*/
}
.addsoft-popup-body>.p.pcb-n4 {
    /*background-image: url('pcb-n4.png');*/
}
.addsoft-popup-body>.p2 {
    color: #666666;
    font-size: 13px;
    line-height: 16px;
    padding-left: 60px;
    margin: 2px 30px 22px 30px;
}

.addsoft-popup-body.pcb>h2 {
    text-align: center;
    border-top: 1px solid #f0f0f0;
    padding-top: 25px;
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    color: #000;
}
.addsoft-popup-body.pcb>h2>span {
    color: #1e94bb;
}

.addsoft-popup-body.fr>h2 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.addsoft-popup-body.fr>h2>span {
    color: #1e94bb;
}

.addsoft-popup-body>.pp {
    color: #747474;
    font-size: 14px;
    line-height: 20px;
    margin: 0 30px;
    margin-bottom: 26px;
    text-align: center;
}
.addsoft-popup-body>.pp1 {
    padding-left: 60px;
    min-height: 49px;
    color: #666666;
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
    margin-bottom: 26px;
    margin-left: 40px;
    margin-right: 40px;
    background-repeat: no-repeat;
    background-position: left center;
}
.addsoft-popup-body>.pp1.fr-n1 {
    /*background-image: url('fr-n1.png');*/
}
.addsoft-popup-body>.pp1.fr-n2 {
    /*background-image: url('fr-n2.png');*/
}
.addsoft-popup-body>.pp1.fr-n3 {
    /*background-image: url('fr-n3.png');*/
}
.addsoft-popup-body>.pp1.fr-n4 {
    /*background-image: url('fr-n4.png');*/
}
.addsoft-popup-body>.pp1>h3 {
    color: #000;
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
}
.addsoft-popup-body>.pcb-video {
    float: left;
    width: 350px;
    height: 250px;
}
.addsoft-popup-body>.pcb-reviews {
    float: right;
    width: 208px;
    padding-right: 30px;
    box-sizing: border-box;
}
.addsoft-popup-body>.pcb-reviews>.review {
    display: none;
}
.addsoft-popup-body>.pcb-reviews>.review>.top {
    height: 56px;
    padding-left: 56px;
    font-size: 17px;
    text-align: center;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 56px 56px;
}
.addsoft-popup-body>.pcb-reviews>.review>.bottom {
    margin-top: 25px;
    font-size: 14px;
    min-height: 110px;
}
.addsoft-popup-body>.pcb-reviews>.review-swither {
    text-align: center;
}
.addsoft-popup-body>.pcb-reviews>.review-swither>span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #d5e2e8;
    cursor: pointer;
    margin: 0 6px;
}
.addsoft-popup-body>.pcb-reviews>.review-swither>span.select {
    background: #f46813;
}
.addsoft-popup-body>.office {
    min-height: 124px;
    padding-left: 40px;
    padding-right: 240px;
    padding-top: 12px;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 235px 124px;
    /*background-image: url('office.png');*/
    font-size: 18px;
    margin-bottom: 20px;
}
.addsoft-popup-body>.office>span {
    display: block;
    font-size: 15px;
    font-stretch: italic;
    margin-top: 10px;
}

.video-link {
    float: left;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    display: block;
    position: relative;
    width:350px;
    height:250px;
    /*background-image: url('product_pcbooster.jpg');*/
}
.video-link span {
    position: absolute;
    top:50%;
    left:50%;
}
.video-link span span {
    /*background: url('c_video_link.png') 0 0 no-repeat transparent;*/
    display: inline-block;
    margin: -35px 0 0 -30px;
    height: 70px;
    width: 61px;
}

.video-link:hover span span {background-position: -61px 0;}
.video-link:active span span {background-position: -122px 0;}

#addsoft-file-recovery.show {
    position: relative;
    z-index: 9;
}
#addsoft-pc-booster.show {
    position: relative;
    z-index: 9;
}

.badge {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-left: 3px;
}

.bold_400 {
    text-shadow: 0 0.3px black;
}

.p-products-elems-pricebase.additional_new {
    margin-top: -12px;
}

.c-select.c-valid + label, .c-input.c-valid + label {
    position: absolute;
    right: 11px;
    top: 14px;
    display: block;
    float: right;
    width: 20px;
    height: 16px;
    background-image: url('../img/c-valid.png');
    background-repeat: no-repeat;
}